//帮助中心---------------------------------------------------------
import {get,put,postHeader,getByIds} from "./base";
//获取列表
export function getHelpCenterLists(data) {
    return getByIds('/api/app/helpers',data)
}
//获取单个
export function getHelpCenterList(id) {
    return get('/api/app/helper/'+id)
}
export function putHelperNumber(id,field,action){
    return put('/api/app/helper/'+id +'/'+field+'/'+action)
}
//意见反馈---------------------------------------------------------
//用户id，例：00a5859eb1784433a5671bf7a3757af7
export function getMyFeedBack(memberId) {
    return get('/v3/yun/app/mine/help/feedback/?memberId='+memberId)
}
export function postMyFeedBack(data) {
    return postHeader('/v3/yun/app/mine/help/feedback',data)
}
