<template>
<!--  帮助中心-->
<div class="container helpCenter" id="helpCenter">
  <van-search
      v-model="searchValue"
      show-action
      placeholder="请输入"
      @search="onSearch"
  >
    <template #action>
      <div style="color: #45A5E6" @click="onSearch">搜索</div>
    </template>
  </van-search>
  <div :style="{height:aType === 'android'||aType ==='ios' ? '':screenHeight+'px',overflowY:aType === 'android'||aType ==='ios' ? '':'scroll'}" class="cellSty">
    <van-cell
        v-for="list in helpList"
        :title="list.title" is-link
        :key="list.id"
        @click="popupBtn(list.id)" />
  </div>
    <van-tabbar :border="true" :fixed="false" :class="[aType === 'android'||aType ==='ios' ? 'tabbar-wrapWin':'tabbar-wrap']">
      <van-tabbar-item  >
        <a style="font-size: medium;color: #646566; " :href="'tel:' + phoneYun">
          <van-icon name="phone-o"/>&nbsp;人工客服</a>
      </van-tabbar-item>|
      <van-tabbar-item @click="goFeed" style="font-size: medium">
        <van-icon name="chat-o"/>&nbsp;<span>意见反馈</span>
      </van-tabbar-item>
    </van-tabbar>
  <van-popup
      v-model="popupShow"
      closeable
      position="bottom"
      :style="{ height: '60%',width:'100%',position:aType === 'android'||aType ==='ios'?'':'absolute' }">
    <div style="padding: 10px">
      <div class="popupTitle" >
        <span>{{listDetail.title}}</span>
      </div>
      <div class="popupInfo">
        <span>
          {{listDetail.reads}}阅读
        </span>
        <span>
          {{listDetail.useful}}人觉得有帮助
        </span>
        <span>
          更新时间：{{listDetail.updateTime}}
        </span>
      </div>
      <van-divider />
      <div class="popupUrl">
        <div v-html="listDetail.content"></div>
<!--        <div>视频会议操作手册—电脑端</div>-->
<!--        <div style="margin-top: 10px"><a style="font-size: medium;color: #0099FF" href="http://www.biying.cn/">http://www.biying.cn/</a></div>-->
      </div>

      <div class="popupBottom">
        <van-divider />
        <div>以上信息是否解决您的问题？</div>
        <div style="margin-bottom: 10px;margin-top: 10px">
          <van-button @click="yesBtn" style="width: 15%;margin-right: 5%;height: 30px" type="default">是</van-button>
          <van-button @click="noBtn" style="width: 15%;height: 30px" type="default">否</van-button>
        </div>
      </div>
    </div>
  </van-popup>
  <!------------------------------------------对话框--------------------------------------------------------------->
  <van-overlay
      style="display: flex;
      justify-content: center;
      align-items: center"
      :show="show">
    <van-loading type="spinner" />
  </van-overlay>
</div>
</template>

<script>


import {phoneYun} from "@/utils/phone";
import {getHelpCenterLists,getHelpCenterList,putHelperNumber} from "@/service/helpCenter";
import {setById} from "@/service/base";
// import {getAdmins} from "@/utils/adminDate";

export default {
  name: "helpCenter",
  data(){
    return{
      helpList:[],
      listDetail:[],
      helpCount:0,
      page_no:1,
      phoneYun:phoneYun(),
      show:false,
      popupShow:false,
      searchValue:'',
      aType:'',
      listId:null,
      screenHeight:0
    }
  },
  created() {
    this.isAndroidOrIOS()
    this.$nextTick(() => {
      var scrollHeight = document.getElementById("helpCenter").scrollHeight;
      this.screenHeight = scrollHeight-60
      console.log(this.screenHeight)
    });

  },
  mounted() {

  },

  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods:{
    //判断手机操作系统(ios或者是Android)
    isAndroidOrIOS() {
      var u = navigator.userAgent;
      // eslint-disable-next-line no-unused-vars
      var app = navigator.appVersion;
      var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //Android终端
      var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
      if (isAndroid) {
        // 这个是安卓操作系统
        this.aType = 'android'
      }
      if (isIOS) {
        // 这个是ios操作系统
        this.aType = 'ios'
      }
      //将获取道德aType保存在本地中
      localStorage.setItem('helpCenterType',this.aType)
      this.getLists()
    },
    async getLists() {
      let arrays = this.$route.query.memberId
      await setById(arrays)
      this.show = true
      let data = {
        page_no:this.page_no,
        page_size:9999,
        aType:this.aType,
        title:this.searchValue,
        os:this.aType,
        channel:'h5'
      }
      const response = await getHelpCenterLists(data)
      if(response.code === 0){
        // this.helpList = response.data.rows
        let list  = response.data.rows
        for (let i=0;i<list.length;i++){
          this.helpList.push({
            id: list[i].id,
            title: list[i].title,
          })
        }
        this.helpCount = response.data.count
      }
      this.show = false
    },

    goFeed(){
      // this.$router.push({path:'/feedBack',query:{memberId:this.$route.query.memberId}})
      this.$router.push({path:'/myFeedBack',query:{memberId:this.$route.query.memberId}})
    },
    onSearch(){
      this.getLists()
    },
    async getList(id) {
      const response = await getHelpCenterList(id)
      if(response.code === 0){
        this.listDetail = response.data
      }
    },
    popupBtn(id){
      this.listId = id
      this.popupShow=true
      this.getList(id)
    },
    async putNumber(field,action) {
      const response = await putHelperNumber(this.listId,field,action)
      if(response.code === 0){
        //  刷新
        await this.getList(this.listId)
      }
    },
    yesBtn(){
      this.putNumber('useful','add')
    },
    noBtn(){
      this.putNumber('useless','cut')
    },
  },

}
</script>

<style scoped>
.helpCenter{
  width: 100%;
}
.tabbar-wrap{
  position: absolute;
  bottom: 0;
  height: 50px;
  line-height: 50px;
}
.tabbar-wrapWin{
  position: fixed;
  bottom: 0;
  height: 50px;
  line-height: 50px;
}
::v-deep .van-pagination__item--active {
  background: #FFFFFF;
  border: none;
  color: #1a1a1a;
}
::v-deep .van-tabbar--fixed{
  align-items: center;
  color: #DDDDDD;
}
.popupInfo{
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  font-size: small;
  color: #9B9B9B;
}
.popupTitle{
  margin-top: 10px;
  font-size: large;
}
.cellSty{
  scrollbar-width: none;
  -ms-overflow-style: none; /* IE 10+ */
}
.cellSty::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
</style>
